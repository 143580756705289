<div  class="app-container">
  <mat-toolbar
    color="primary"
    *ngIf="(auth$| async) as auth"
    >
    <div style="cursor: pointer;" routerLink="/">Indopos</div>
    <span class="flex-spacer"></span>
    <button
      *ngIf="auth"
      mat-icon-button
      [matMenuTriggerFor]="menu"
      matTooltip="Click to see more selections"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        *ngIf="auth"
        mat-menu-item
        routerLink="/logout"
        matTooltip="Sign out from this account"
        aria-label="Logout"
      >
        <mat-icon>logout</mat-icon>
        Logout
      </button>
    </mat-menu>
  </mat-toolbar>
  <router-outlet class="main-router-outlet"></router-outlet>
</div>
