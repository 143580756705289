import {Component} from '@angular/core';
import {AuthService} from '@indosuara/auth';

@Component({
  selector: 'indosuara-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(
    public authService: AuthService,
  ) {
    this.auth$ = this.authService.authState();
  }
  auth$;
}
